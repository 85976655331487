import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { TYPE } from 'theme';

import { getNetwork } from 'config/network';
import { useConnectedWeb3Context } from 'contexts';

import MarketCard from 'components/MarketCard';

const StyledFilledButton = styled.button`
  border: none;
  padding: 1rem 5rem;
  font-size: 0.875rem;
  color: white;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.primary};
`;

const MarketContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 5rem 6.25rem;
`;

const MarketView = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
`;

const Market: React.FC = () => {
  const { networkId } = useConnectedWeb3Context();
  const { markets, marketInfos } = getNetwork(networkId).info;

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding="4.325rem 6.25rem"
        backgroundColor="white"
      >
        <Box width="30%" minWidth="28.375rem">
          <TYPE.Header1 color="text1" mb="1.25rem">
            Predict the Future
          </TYPE.Header1>
          <TYPE.Body1 color="text3" mb="2.5rem">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam
          </TYPE.Body1>

          {/* <StyledFilledButton>LOGIN</StyledFilledButton> */}
        </Box>
      </Flex>
      <MarketContainer>
        <TYPE.Header2 color="text1" mb="3.75rem" textAlign="center">
          The hottest markets
        </TYPE.Header2>
        <MarketView>
          {markets.map((market) => (
            <MarketCard
              key={market}
              market={market}
              marketInfo={marketInfos[market]}
            />
          ))}
        </MarketView>
      </MarketContainer>
    </>
  );
};

export default Market;
