import WalletConnectProvider from '@walletconnect/web3-provider';
import { networkIds } from 'config/network';
import React, { useCallback, useEffect, useState } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
export interface IConnectedWeb3Context {
  account: Maybe<string> | undefined;
  web3: Web3 | undefined;
  networkId: number;
  initialized: boolean;
  slippage: number;
  connectWeb3: () => void;
  disconnect: () => void;
  setSlippage: (_: number) => void;
}

export const ConnectedWeb3Context = React.createContext<
  Maybe<IConnectedWeb3Context>
>(null);

/**
 * This hook can only be used by components under the `ConnectedWeb3` component. Otherwise it will throw.
 */
export const useConnectedWeb3Context = () => {
  const context = React.useContext(ConnectedWeb3Context);

  if (!context) {
    throw new Error('Component rendered outside the provider tree');
  }

  return context;
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

export const ConnectedWeb3: React.FC = (props) => {
  // use mainnet as default
  const [networkId, setNetworkId] = useState<number>(networkIds.KOVAN);
  const [web3, setWeb3] = useState<Web3 | undefined>(undefined);
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [initialized, setInitialized] = useState(true);
  const [slippage, setSlippage] = useState(0.1);

  const setWeb3Provider = async (prov: any, updateAccount = false) => {
    if (prov) {
      const web3Provider = new Web3(prov);

      setWeb3(web3Provider);
      const networkId = await web3Provider.eth.getChainId();
      setNetworkId(networkId);

      if (updateAccount) {
        const gotAccounts = await web3Provider.eth.getAccounts();
        setAccount(gotAccounts[0]);
      }
    }
  };

  const connectWeb3 = useCallback(async () => {
    try {
      setInitialized(true);
      const modalProvider = await web3Modal.connect();

      await setWeb3Provider(modalProvider, true);

      // Subscribe to accounts change
      modalProvider.on('accountsChanged', (accounts: string[]) => {
        setAccount(accounts[0]);
      });

      // Subscribe to chainId change
      modalProvider.on('chainChanged', () => {
        setWeb3Provider(modalProvider);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ web3ModalError: error });
    }
    setInitialized(false);
  }, []);

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider();
    setAccount(undefined);
    setWeb3(undefined);
    setNetworkId(networkIds.KOVAN);
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }
    if (web3Modal.cachedProvider) {
      setInitialized(true);
      connectWeb3().catch((error) => {
        // eslint-disable-next-line
        console.error({ web3ModalError: error });
      });
    } else {
      setInitialized(false);
    }
  }, [connectWeb3]);

  return (
    <ConnectedWeb3Context.Provider
      value={{
        account,
        web3,
        networkId,
        slippage,
        initialized,
        connectWeb3,
        disconnect,
        setSlippage,
      }}
    >
      {props.children}
    </ConnectedWeb3Context.Provider>
  );
};

export const WhenConnected: React.FC = (props) => {
  const { account } = useConnectedWeb3Context();

  return <>{account && props.children}</>;
};
