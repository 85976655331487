import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { Flex } from 'rebass';
import { Colors } from 'theme/styled';
import { Currency } from 'types';
import { ReactComponent as DropIcon } from 'assets/images/drop.svg';
import { useOuterClickNotifier } from 'hooks/useOuterClickNotifier';

export const Input = styled.input<{ bgColor: keyof Colors }>`
  background-color: ${({ bgColor, theme }) => (theme as any)[bgColor]};
  border: ${({ theme }) => `1px solid ${theme.border}`};
  color: ${({ theme }) => theme.text1};
  padding: 1rem 1.25rem;
  outline: none;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6875rem;
  border-radius: 4px;
  min-width: 0;
`;

const CurrencySelect = styled.div`
  width: 105px;
  border-radius: 0.75rem;
  background: white;
  border: ${({ theme }) => `1px solid ${theme.border}`};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

const CurrencyOption = styled.div`
  padding: 0.5rem;
  cursor: pointer;
`;

interface IProps {
  currency: Currency;
  value: number;
  bgColor: keyof Colors;
  index: number;
  changeCurrency: (currency: Currency, index: number) => void;
  changeValue: (value: number, index: number) => void;
}

const CurrencyInput: React.FC<IProps> = ({
  index,
  currency,
  value,
  bgColor,
  changeCurrency,
  changeValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputVal, setInputVal] = useState('0');
  const currencyList = ['YES', 'NO', 'USDC'].filter((c) => c !== currency);

  const menuRef = useRef(null);

  useOuterClickNotifier(() => {
    setIsOpen(false);
  }, [menuRef]);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setInputVal('');
      changeValue(0, index);
    } else if (!isNaN(Number(event.target.value))) {
      changeValue(Number(event.target.value), index);
      setInputVal(event.target.value);
    }
  };

  const onChangeCurrency = (event: React.MouseEvent, el: Currency) => {
    event.stopPropagation();
    setIsOpen(false);
    changeCurrency(el, index);
  };

  useEffect(() => {
    setInputVal(value.toString());
  }, [value]);

  return (
    <>
      <Flex alignItems="center">
        <Flex
          minWidth="105px"
          alignItems="center"
          mr="1rem"
          sx={{ cursor: 'pointer', position: 'relative' }}
          onClick={() => setIsOpen(true)}
        >
          <TYPE.Header3 mr="1rem">{currency}</TYPE.Header3>
          <DropIcon />
          {isOpen && (
            <CurrencySelect ref={menuRef}>
              {currencyList.map((el) => (
                <CurrencyOption
                  key={el}
                  onClick={(e) => onChangeCurrency(e, el as Currency)}
                >
                  <TYPE.Header3>{el}</TYPE.Header3>
                </CurrencyOption>
              ))}
            </CurrencySelect>
          )}
        </Flex>
        <Input
          placeholder="0.0"
          bgColor={bgColor}
          value={inputVal}
          type="number"
          onChange={(e) => onChangeInput(e)}
        />
      </Flex>
    </>
  );
};

export default CurrencyInput;
