import { getNetwork, networkIds } from 'config/network';
import { BigNumber, ethers } from 'ethers';
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from 'ethers/lib/utils';

export const MAX_UINT256 = ethers.constants.MaxUint256.toString();
export const ETH_IN_WEI = ethers.constants.WeiPerEther;

export const shortenAddress = (address: string) => {
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4
  )}`;
};

export const numberWithCommas = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatToShortNumber = (number: string, decimals = 2): string => {
  if (number.length < 1) {
    return '0';
  }

  const units = ['', 'K', 'M', 'B', 'T'];
  let unitIndex = 0;
  let rNumber = parseFloat(number.split(',').join(''));

  while (rNumber >= 1000 && unitIndex < 5) {
    unitIndex += 1;
    rNumber = rNumber / 1000;
  }

  return `${parseFloat(rNumber.toFixed(decimals))}${units[unitIndex]}`;
};

export const formatBigNumber = (
  value: BigNumber,
  decimals = 18,
  precision = 2
): string => Number(formatUnits(value, decimals)).toFixed(precision);

export const convertAmountToDisplay = (
  amount: string,
  token: string,
  networkId: number
) => {
  // if the token is yes/no then decimals are 15
  const { info: contracts } = getNetwork(networkId);
  const tokenMultiple =
    networkId === networkIds.KOVAN ? BigNumber.from(100) : BigNumber.from(1000);

  let value = BigNumber.from(amount);
  if (token !== contracts.dai) {
    value = value.mul(tokenMultiple);
  }

  return formatBigNumber(value);
};

export const convertDisplayToAmount = (
  amount: string | number,
  token: string,
  networkId: number
) => {
  const { info: contracts } = getNetwork(networkId);
  const tokenMultiple =
    networkId === networkIds.KOVAN ? BigNumber.from(100) : BigNumber.from(1000);

  let bg = parseUnits(amount.toString(), 18);
  if (token !== contracts.dai) {
    bg = bg.div(tokenMultiple);
  }
  return bg.toString();
};

export const convertSpotPriceToDisplay = (
  price: string,
  token: string,
  networkId: number
) => {
  const { info: contracts } = getNetwork(networkId);
  const tokenMultiple =
    networkId === networkIds.KOVAN ? BigNumber.from(100) : BigNumber.from(1000);

  let amount = BigNumber.from(price);
  // if the token is yes/no then decimals are 15
  if (token !== contracts.dai) {
    amount = amount.div(tokenMultiple);
  }
  return formatBigNumber(amount);
};

export const weiToNumber = (weiVal: string) =>
  parseFloat(formatEther(weiVal).toString());

/**********************************************************************************************
    // calcSpotPrice                                                                             //
    // sP = spotPrice                                                                            //
    // bI = tokenBalanceIn                ( bI / wI )         1                                  //
    // bO = tokenBalanceOut         sP =  -----------  *  ----------                             //
    // wI = tokenWeightIn                 ( bO / wO )     ( 1 - sF )                             //
    // wO = tokenWeightOut                                                                       //
    // sF = swapFee                                                                              //
    **********************************************************************************************/
export function calcSpotPrice(
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  swapFee: string
) {
  if (tokenWeightIn === '0' || tokenBalanceOut === '0') return '0';
  return parseEther(
    (
      (weiToNumber(tokenBalanceIn) * weiToNumber(tokenWeightOut)) /
      weiToNumber(tokenBalanceOut) /
      weiToNumber(tokenWeightIn) /
      (1 - weiToNumber(swapFee))
    ).toFixed(18)
  ).toString();
}

/**********************************************************************************************
    // calcOutGivenIn                                                                            //
    // aO = tokenAmountOut                                                                       //
    // bO = tokenBalanceOut                                                                      //
    // bI = tokenBalanceIn              /      /            bI             \    (wI / wO) \      //
    // aI = tokenAmountIn    aO = bO * |  1 - | --------------------------  | ^            |     //
    // wI = tokenWeightIn               \      \ ( bI + ( aI * ( 1 - sF )) /              /      //
    // wO = tokenWeightOut                                                                       //
    // sF = swapFee                                                                              //
    **********************************************************************************************/
export function calcOutGivenIn(
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  tokenAmountIn: string,
  swapFee: string
) {
  if (weiToNumber(tokenWeightOut) === 0) {
    return '0';
  }

  const weightRatio = weiToNumber(tokenWeightIn) / weiToNumber(tokenWeightOut);

  const adjustedIn =
    (1 - weiToNumber(swapFee)) * weiToNumber(tokenAmountIn) +
    weiToNumber(tokenBalanceIn);

  const y = weiToNumber(tokenBalanceIn) / adjustedIn;
  const foo = Math.pow(y, weightRatio);

  if (foo > 1) {
    return '0';
  }

  return parseEther(
    (weiToNumber(tokenBalanceOut) * (1 - foo)).toFixed(18)
  ).toString();
}

/**********************************************************************************************
    // calcInGivenOut                                                                            //
    // aI = tokenAmountIn                                                                        //
    // bO = tokenBalanceOut               /  /     bO      \    (wO / wI)      \                 //
    // bI = tokenBalanceIn          bI * |  | ------------  | ^            - 1  |                //
    // aO = tokenAmountOut    aI =        \  \ ( bO - aO ) /                   /                 //
    // wI = tokenWeightIn           --------------------------------------------                 //
    // wO = tokenWeightOut                          ( 1 - sF )                                   //
    // sF = swapFee                                                                              //
    **********************************************************************************************/
export function calcInGivenOut(
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  tokenAmountOut: string,
  swapFee: string
) {
  if (
    weiToNumber(tokenWeightIn) === 0 ||
    weiToNumber(tokenBalanceOut) <= weiToNumber(tokenAmountOut)
  ) {
    return '0';
  }

  const weightRatio = weiToNumber(tokenWeightOut) / weiToNumber(tokenWeightIn);

  const y =
    weiToNumber(tokenBalanceOut) /
    (weiToNumber(tokenBalanceOut) - weiToNumber(tokenAmountOut));

  const foo = Math.pow(y, weightRatio);
  if (foo < 1) {
    return '0';
  }

  const adjust = weiToNumber(tokenBalanceIn) * (Math.pow(y, weightRatio) - 1);
  const amountIn = adjust / (1 - weiToNumber(swapFee));

  return parseEther(amountIn.toFixed(18)).toString();
}

export function calcPoolTokensFromAmount(
  amountIn: BigNumber,
  balanceIn: BigNumber,
  totalShares: BigNumber
) {
  return amountIn
    .mul(totalShares.sub(BigNumber.from(1)))
    .div(balanceIn.add(BigNumber.from(1)))
    .toString();
}

export const etherscanUrl = (txHash: string, networkId: number) => {
  return `https://${networkId !== 1 ? 'kovan.' : ''}etherscan.io/tx/${txHash}`;
};

export const bMin = (a: string, b: string) => {
  return BigNumber.from(a).lt(BigNumber.from(b)) ? a : b;
};

export const bMax = (a: string, b: string) => {
  return BigNumber.from(a).lt(BigNumber.from(b)) ? b : a;
};
