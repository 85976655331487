import React from 'react';
import { Flex } from 'rebass';
import styled, { DefaultTheme } from 'styled-components';
import { theme, TYPE } from 'theme';

const TabItem = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  height: 28px;
  padding: 0;
  margin-right: 40px;
  box-sizing: border-box;
  border-bottom: ${({
    theme,
    selected,
  }: {
    theme: DefaultTheme;
    selected: boolean;
  }) => (selected ? `2px solid ${theme.text1}` : 'none')}; ;
`;

interface IProps {
  tabs: string[];
  selected: number;
  onChange: (selected: number) => void;
}

const Tabs: React.FC<IProps> = ({ tabs, selected, onChange }) => {
  return (
    <Flex>
      {tabs.map((tab, idx) => (
        <TabItem
          key={idx}
          selected={selected === idx}
          onClick={() => onChange(idx)}
        >
          <TYPE.Upper1 color={selected === idx ? theme.text1 : theme.text4}>
            {tab}
          </TYPE.Upper1>
        </TabItem>
      ))}
    </Flex>
  );
};

export default Tabs;
