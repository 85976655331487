import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { useHistory } from 'react-router-dom';
import { TYPE } from 'theme';
import Badge from 'components/common/Badge';

import abis from 'config/abis';
import { ABIKeys, getNetwork } from 'config/network';
import { convertSpotPriceToDisplay } from 'utils';
import { useConnectedWeb3Context } from 'contexts';
import { IMarketInfo } from 'types';

const MarketCardContainer = styled.div`
  background: white;
  border: none;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const TopContent = styled.div`
  padding: 1.25rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.bg1}`};
`;

const BottomContent = styled.div`
  padding: 1.25rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoContainer = styled(Box)`
  background: ${({ theme }) => theme.bg2} !important;
  border-radius: 6px !important;
`;

interface IProps {
  marketInfo: IMarketInfo;
  market: string;
}

const MarketCard: React.FC<IProps> = ({ marketInfo, market }) => {
  const { networkId, web3 } = useConnectedWeb3Context();
  const {
    info: { dai },
    balancerGraphQL,
  } = getNetwork(networkId);
  const history = useHistory();

  const [yesBid, setYesBid] = useState('0.00');
  const [noBid, setNoBid] = useState('0.00');
  const [liquidity, setLiquidity] = useState('0.00');

  const onClickCard = () => {
    history.push(`/trade/${market}`);
  };

  useEffect(() => {
    async function fetchBids() {
      const poolAddress = marketInfo.pool;
      const yesAddress = marketInfo.yes;
      const noAddress = marketInfo.no;

      const poolContract = new web3!.eth.Contract(
        abis[ABIKeys.bPool].abi,
        poolAddress
      );

      const [yesPrice, noPrice] = await Promise.all([
        poolContract.methods.getSpotPrice(dai, yesAddress).call(),
        poolContract.methods.getSpotPrice(dai, noAddress).call(),
      ]);

      setYesBid(convertSpotPriceToDisplay(yesPrice, yesAddress, networkId));
      setNoBid(convertSpotPriceToDisplay(noPrice, noAddress, networkId));
    }

    if (web3 && marketInfo && dai) {
      fetchBids();
    }
  }, [web3, marketInfo, dai]);

  useEffect(() => {
    fetch(balancerGraphQL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
            {
              pools(where: {id: "${marketInfo.pool}", publicSwap: true}) {
                id
                liquidity
              }
            }
          `,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data && res.data.pools && res.data.pools[0]) {
          setLiquidity(Number(res.data.pools[0].liquidity).toFixed(2));
        }
      });
  }, [marketInfo.pool]);

  return (
    <MarketCardContainer onClick={onClickCard}>
      <TopContent>
        <Flex flexDirection="row" justifyContent="space-between">
          <InfoContainer width="33%" />
          <Flex flexDirection="column" width="59%">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              mb="0.5rem"
            >
              <InfoContainer width="48%" padding="0.5rem 0">
                <Flex justifyContent="center">
                  <TYPE.Upper1 color="text3" mr="0.5rem">
                    YES
                  </TYPE.Upper1>
                  <TYPE.Upper1 color="text2">${yesBid}</TYPE.Upper1>
                </Flex>
              </InfoContainer>
              <InfoContainer width="48%" padding="0.5rem 0">
                <Flex justifyContent="center">
                  <TYPE.Upper1 color="text3" mr="0.5rem">
                    NO
                  </TYPE.Upper1>
                  <TYPE.Upper1 color="text2"> ${noBid}</TYPE.Upper1>
                </Flex>
              </InfoContainer>
            </Flex>
            <InfoContainer padding="0.5rem 1rem">
              <TYPE.Caption color="text3">TOTAL VOLUME</TYPE.Caption>
              <TYPE.Header3 color="text1">${liquidity}</TYPE.Header3>
            </InfoContainer>
          </Flex>
        </Flex>
      </TopContent>
      <BottomContent>
        <TYPE.Header4 color="text1" mb="1.25rem">
          {marketInfo.marketQuestion}
        </TYPE.Header4>
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
          {marketInfo.extraInfo.categories.map((category) => (
            <Badge text={category} key={category} />
          ))}
        </Flex>
      </BottomContent>
    </MarketCardContainer>
  );
};

export default MarketCard;
