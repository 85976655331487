import React, { useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { Flex } from 'rebass';

import { BigNumber } from 'ethers';
import { useConnectedWeb3Context } from 'contexts';
import { convertDisplayToAmount } from 'utils/helpers';
import { getNetwork } from 'config/network';

import { ModalWrapper } from './ModalWrapper';
import { Input } from '../common/CurrencyInput';

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.93934 13.7135C0.353553 14.2993 0.353553 15.2491 0.93934 15.8349C1.52513 16.4206 2.47487 16.4206 3.06066 15.8349L8.38708 10.5084L13.7135 15.8349C14.2993 16.4207 15.2491 16.4207 15.8349 15.8349C16.4206 15.2491 16.4206 14.2993 15.8349 13.7136L10.5084 8.38711L15.8349 3.06066C16.4206 2.47487 16.4206 1.52513 15.8349 0.939339C15.2491 0.353553 14.2993 0.353554 13.7135 0.93934L8.38708 6.26579L3.06066 0.939364C2.47487 0.353577 1.52513 0.353577 0.93934 0.939364C0.353553 1.52515 0.353553 2.4749 0.93934 3.06068L6.26576 8.38711L0.93934 13.7135Z"
      fill="#4E4B66"
    />
  </svg>
);

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.25rem 0;
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

const StyledFilledButton = styled.button`
  border: none;
  color: white;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 6.25rem;
  padding: 1rem 4rem;
`;

interface IProps {
  isOpen: boolean;
  addOrRemove: 'add' | 'remove';
  addLiquidity: (amount: string) => void;
  removeLiquidity: (amount: string) => void;
  onClose: () => void;
}

const Liquidity: React.FC<IProps> = ({
  isOpen,
  addOrRemove,
  addLiquidity,
  removeLiquidity,
  onClose,
}) => {
  const { networkId } = useConnectedWeb3Context();
  const {
    info: { dai },
  } = getNetwork(networkId);

  const [inputVal, setInputVale] = useState(0);

  const onClickClose = () => {
    onClose();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(e.target.value))) {
      setInputVale(Number(e.target.value));
    }
  };

  const onClickAction = () => {
    const amount = convertDisplayToAmount(inputVal.toString(), dai, networkId);
    if (BigNumber.from(amount).isZero()) {
      return;
    }
    addOrRemove === 'add' ? addLiquidity(amount) : removeLiquidity(amount);
  };

  return (
    <ModalWrapper isOpen={isOpen} onRequestClose={onClose}>
      <ContentWrapper>
        <CloseIcon onClick={onClickClose} />
        <TYPE.Header2 color="text1" mb="1.25rem">
          {addOrRemove === 'add' ? 'Add Liquidity' : 'Remove Liquidity'}
        </TYPE.Header2>
        <TYPE.Body1 color="text2" mb="5rem">
          Lorem ipsum dolor sir amet
        </TYPE.Body1>
        <Flex mb="6.25rem" alignItems="center">
          <Input
            placeholder="0.0"
            bgColor="bg1"
            value={inputVal}
            onChange={onChangeInput}
            type="number"
          />
          <TYPE.Header3 ml="2.5rem">
            {addOrRemove === 'add' ? 'USDC' : 'POOl'}
          </TYPE.Header3>
        </Flex>
        <StyledFilledButton onClick={onClickAction}>
          {addOrRemove === 'add' ? 'ADD LIQUIDITY' : 'REMOVE LIQUIDITY'}
        </StyledFilledButton>
      </ContentWrapper>
    </ModalWrapper>
  );
};

export default Liquidity;
