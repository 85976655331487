import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { TYPE } from 'theme';

const BadgeContainer = styled(Box)`
  padding: 0.5rem 1.25rem !important;
  background: rgba(110, 113, 145, 0.2) !important;
  border-radius: 100px !important;
  margin-right: 0.625rem !important;
  margin-bottom: 0.625rem !important;
`;

interface IProps {
  text: string;
}

const Badge: React.FC<IProps> = ({ text }) => {
  return (
    <BadgeContainer>
      <TYPE.Upper1 color="text3">{text}</TYPE.Upper1>
    </BadgeContainer>
  );
};

export default Badge;
