import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { IPortfolioInfo } from 'types';
import { formatToShortNumber } from 'utils';

import { ReactComponent as ChartIcon } from 'assets/images/icn_chart.svg';
import { ReactComponent as StarIcon } from 'assets/images/icn_star.svg';
import { ReactComponent as CartIcon } from 'assets/images/icn_cart.svg';

const InfoContainer = styled(Box)`
  background: ${({ theme }) => theme.white} !important;
  border-radius: 8px !important;
`;

const Divider = styled(Box)`
  background: ${({ theme }) => theme.bg1};
  width: 2px;
  height: 160px;
  margin: 0 30px !important;
`;

interface IProps {
  portfolio: IPortfolioInfo;
}

const PortfolioInfo: React.FC<IProps> = ({ portfolio }) => {
  return (
    <InfoContainer>
      <Flex
        padding="1rem 2rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flex={1}>
          <ChartIcon />
          <TYPE.Upper2 color="text3" mt={28} mb={10}>
            Portfolio Value
          </TYPE.Upper2>
          <TYPE.Caption
            color="text1"
            fontSize="48px"
            lineHeight="62px"
            fontWeight={300}
          >
            $ {formatToShortNumber(portfolio.portfolioValue)}
          </TYPE.Caption>
        </Box>

        <Divider />

        <Box flex={1}>
          <StarIcon />
          <TYPE.Upper2 color="text3" mt={28} mb={10}>
            Open Positions
          </TYPE.Upper2>
          <TYPE.Caption
            color="text1"
            fontSize="48px"
            lineHeight="62px"
            fontWeight={300}
          >
            $ {formatToShortNumber(portfolio.openPositions)}
          </TYPE.Caption>
        </Box>

        <Divider />

        <Box flex={1}>
          <CartIcon />
          <TYPE.Upper2 color="text3" mt={28} mb={10}>
            Cash
          </TYPE.Upper2>
          <TYPE.Caption
            color="text1"
            fontSize="48px"
            lineHeight="62px"
            fontWeight={300}
          >
            $ {formatToShortNumber(portfolio.cash)}
          </TYPE.Caption>
        </Box>
      </Flex>
    </InfoContainer>
  );
};

export default PortfolioInfo;
