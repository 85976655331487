import React, { useEffect } from 'react';
import _ from 'lodash';

export const useOuterClickNotifier = (
  onOuterClick: any,
  innerRef: any,
  // Ignores Selects, and GA overlay
  ignoreClasses = ['StyledDrop', 'LayerModal'],
  ignoreIds = ['__ga_grayLayout__']
) => {
  useEffect(() => {
    let handleClick: any;
    if (_.isArray(innerRef)) {
      handleClick = (e: React.MouseEvent<HTMLElement>) => {
        let clickedInside = false;

        if (!document.contains(e.target as any)) {
          clickedInside = true;
        }

        innerRef.forEach((singleRef) => {
          clickedInside =
            clickedInside ||
            (singleRef.current && singleRef.current.contains(e.target));
        });

        if (ignoreClasses) {
          ignoreClasses.forEach((singleClass) => {
            const elements = document.querySelectorAll(
              `[class*="${singleClass}"]`
            );
            if (elements.length) {
              elements.forEach((singleElement) => {
                clickedInside =
                  clickedInside || singleElement.contains(e.target as any);
              });
            }
          });
        }

        if (ignoreIds) {
          ignoreIds.forEach((singleClass) => {
            const elements = document.querySelectorAll(
              `[id*="${singleClass}"]`
            );
            if (elements.length) {
              elements.forEach((singleElement) => {
                clickedInside =
                  clickedInside || singleElement.contains(e.target as any);
              });
            }
          });
        }

        if (!clickedInside) {
          onOuterClick(e);
        }
      };

      let result = false;
      innerRef.forEach((singleRef) => {
        result = result || singleRef.current;
      });
      if (result) {
        window.addEventListener('click', handleClick);
      }
    } else {
      handleClick = (e: any) => {
        innerRef.current &&
          !innerRef.current.contains(e.target) &&
          onOuterClick(e);
      };

      if (innerRef.current) {
        window.addEventListener('click', handleClick);
      }
    }

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [onOuterClick, innerRef, ignoreClasses, ignoreIds]);
};
