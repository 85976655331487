import { INetwork, NetworkId } from 'types';
import { entries } from 'utils/type-utils';

export const networkIds = {
  MAINNET: 1,
  KOVAN: 42,
} as const;

export const ABIKeys = {
  bActions: 'bActions',
  bFactory: 'bFactory',
  bPool: 'bPool',
  dsProxy: 'dsProxy',
  dsProxyRegistry: 'dsProxyRegistry',
  erc20: 'erc20',
  exchangeProxy: 'exchangeProxy',
  weth: 'weth',
  multiCall: 'multiCall',
  liquidityHelper: 'liquidityHelper',
  shareToken: 'shareToken',
  market: 'market',
  erc20Wrapper: 'erc20Wrapper',
} as const;

export const InfuraId = process.env.REACT_APP_INFURA_ID || '';

export const supportedNetworkIds = Object.values(networkIds).map(
  Number
) as NetworkId[];

export const networks: { [K in NetworkId]: INetwork } = {
  [networkIds.MAINNET]: {
    label: 'ETH Mainnet',
    url: `https://mainnet.infura.io/v3/${InfuraId}`,
    augurGraphQL: 'https://api.thegraph.com/subgraphs/name/protofire/augur-v2',
    balancerGraphQL:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer',
    etherscanUri: 'https://etherscan.io/',
    info: {
      dai: '0x6b175474e89094c44da98b954eedeac495271d0f',
      multicall: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
      liquidity_helper: '0x11b9e286828f05454E5C8C65e734b01Ec3477F25',
      shareToken: '0x9e4799ff2023819b1272eee430eadf510eDF85f0',
      markets: ['0x46c240fa9c68daccb66949823d1f12cf02da7c50'],
      marketInfos: {
        '0x46c240fa9c68daccb66949823d1f12cf02da7c50': {
          yes: '0x74b2dd0e7dd7be5e6cea2d7c5e1da3d05d9e36b8',
          no: '0x44f65f6dbacbc836f052c85ce46012c8b6da95fc',
          pool: '0xdc2be78817492a8c10664f8d1e67252453f11b27',
          symbolPostfix: 'Trump',
          yesIcon:
            'https://cloudflare-ipfs.com/ipfs/QmcrygUUrksinSjewEvTvAE5yZtUCpAPVRjb46HwcjNx5s',
          noIcon:
            'https://cloudflare-ipfs.com/ipfs/QmQVw7Mb9X6NvThWfgSVncwGh53vZvnBNLZzWHMufjFqdD',
          marketQuestion:
            'NFL: Will the Kansas City Chiefs win SuperBowl LV (55th)?',
          extraInfo: {
            categories: ['Politics', 'US Politics', 'President'],
            description:
              'Will Donald Trump win the 2020 U.S. Presidential election?',
            longDescription:
              "A candidate that receives at least 270 votes in the Electoral College shall be considered the winner. In the event that no candidate receives 270 votes, the House of Representatives will decide the winner. In the event of further indecision or tie, it will be the candidate determined to be the winner under the US Constitution.\nThis market is intended to be about a Single Candidate, if this is not the case, this market should settle as 'Invalid'.",
          },
          endTime: 1611273600,
        },
        '0x6e6ffb10179febf29b0223d22793d1c1d8a8f541': {
          yes: '0xf732ee9ccbf0df22f53fbdd9ec88d62df298fbf8',
          no: '0x542439eca879e52e03e0d6e87bcdca165634245d',
          pool: '0x68c74e157f35a3e40f1b02bba3e6e3827d534059',
          symbolPostfix: 'Blue',
          yesIcon:
            'https://cloudflare-ipfs.com/ipfs/QmUA2ExuPSRPHepiRqe3VH4oZKHkw6URoNtFB7UqHN8Vgh',
          noIcon:
            'https://cloudflare-ipfs.com/ipfs/QmZfAjSUevTSJe5Lqg22vx4zAPGrkafDKz33LJG2GWoAqG',
          marketQuestion:
            'Will the Democrats Win the Presidency, the Senate, and the House?',
          extraInfo: {
            categories: ['Politics', 'US Elections', ''],
            description:
              'Will the Democratic Party Control the Presidency, the Senate, and the House of Representatives on February 1st, 2021?',
            longDescription:
              'This market shall resolve as YES if and only if all three of the following conditions are true at 00:00 UTC on February 1st, 2021:\n\n1. The last inaugurated president of the U.S. is affiliated with the U.S. Democratic Party.\n2. The Senate Majority Leader is affiliated with the U.S. Democratic Party. \n3. The majority of voting members of the House of Representatives are affiliated with the U.S. Democratic Party',
          },
          endTime: 1612137600,
        },
        '0x0946a3eab3d638c606459b0a9f1e76eb5324985f': {
          yes: '0x934e03e671e09528a52cb3373dc7bb7ca475b924',
          no: '0x55b2969a6d832c6091ba1af4ed6d0e6e7c2c0d90',
          pool: '0x91ffe46e57d7c3f5f6e4f0856f2a674d0d820f93',
          symbolPostfix: 'INAUG',
          yesIcon:
            'https://cloudflare-ipfs.com/ipfs/Qmc14RQHBa9zezBYERJqMDAknkfmC14VN8coPz9KYiMKMU',
          noIcon:
            'https://cloudflare-ipfs.com/ipfs/QmPPHjD2wYJzs46kfYrGFsELLj2a2NQjiKWtoHTs1yVvfF',
          marketQuestion:
            'Will Donald Trump Attend the 2021 U.S. Presidential Inauguration?',
          extraInfo: {
            categories: ['Politics', 'US Elections', 'Misc'],
            description:
              'Will 45th U.S. president Donald Trump physically attend the 59th U.S. presidential inauguration?',
            longDescription:
              'This market shall resolve as YES if and only if Donald Trump, the 45th U.S. president, physically attends the 59th U.S. presidential inauguration ceremony, scheduled for January 20th, 2021 at market creation time. The resolution of this market shall be based on general knowledge, as the ceremony has historically been widely covered by a number of media outlets, including video records.',
          },
          endTime: 1611273600,
        },
      },
    },
  },
  [networkIds.KOVAN]: {
    label: 'Kovan Testnet',
    url: `https://kovan.infura.io/v3/${InfuraId}`,
    augurGraphQL:
      'https://api.thegraph.com/subgraphs/name/protofire/augur-v2-kovan',
    balancerGraphQL:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-kovan',
    etherscanUri: 'https://kovan.etherscan.io/',
    info: {
      dai: '0xb6085abd65e21d205aead0b1b9981b8b221fa14e',
      multicall: '0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a',
      liquidity_helper: '0xF11405BcfC24D026D57f44A2D56e9D7b86B6315F',
      shareToken: '0xFFaFFda91CF0a333DA64a046B1506213A053B142',
      markets: ['0xd3ba2a2e641f61a5bcb7a772c49ba6b78e1416e0'],
      marketInfos: {
        '0xd3ba2a2e641f61a5bcb7a772c49ba6b78e1416e0': {
          yes: '0xac9c1c55901c51b4ff78d957e66bbfe35580528b',
          no: '0xf7ef92d2a34137dfa2d60a983eb68dbf0ec3db07',
          pool: '0x494f67aa74c47b3e1b3568e74f9f44365a6c1133',
          symbolPostfix: 'Anthony',
          yesIcon:
            'https://cloudflare-ipfs.com/ipfs/QmUA2ExuPSRPHepiRqe3VH4oZKHkw6URoNtFB7UqHN8Vgh',
          noIcon:
            'https://cloudflare-ipfs.com/ipfs/QmZfAjSUevTSJe5Lqg22vx4zAPGrkafDKz33LJG2GWoAqG',
          marketQuestion:
            'Will Anthony Davis win the 2019-20 Defensive Player of the Year award?',
          extraInfo: {
            categories: ['Sports', 'Basketball', 'NBA'],
            description:
              ' Will Anthony Davis win the 2019-20 Defensive Player of the Year award?',
            longDescription:
              "In the event of an award given to more than 1 player. If the player mentioned in the market is one of the players who wins the award, the market should resolve as Yes.This market is intended to be about a Single Person, if this is not the case, this market should settle as 'Invalid'. If the award in the market question is not awarded for any reason by event expiration, this market should resolve as 'No'.",
          },
          endTime: 1605312000,
        },
      },
    },
  },
};

export const supportedNetworkURLs = entries(networks).reduce<{
  [networkId: number]: string;
}>(
  (acc, [networkId, network]) => ({
    ...acc,
    [networkId]: network.url,
  }),
  {}
);

const validNetworkId = (networkId: number): networkId is NetworkId => {
  return networks[networkId as NetworkId] !== undefined;
};

export const getNetwork = (networkId: number): INetwork => {
  if (!validNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`);
  }
  return networks[networkId];
};
