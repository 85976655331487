import { useEffect, useState } from 'react';
import { useConnectedWeb3Context } from 'contexts';
import { Contract } from 'web3-eth-contract';
import { getNetwork } from 'config/network';
import { BigNumber } from 'ethers';
import abis from 'config/abis';

import { MAX_UINT256, bMin, calcPoolTokensFromAmount } from 'utils';
import { IChainInfo, IContracts } from 'types';

export const useMarket = (market: string) => {
  const { account, networkId, slippage, web3 } = useConnectedWeb3Context();
  const {
    info: { dai, marketInfos, multicall, shareToken, liquidity_helper },
  } = getNetwork(networkId);
  const marketInfo = marketInfos[market];

  const BINARY_MARKET_OUTCOMES = [0, 1, 2];

  const [contracts, setContracts] = useState<IContracts>({
    yesToken: null,
    noToken: null,
    daiToken: null,
    shareToken: null,
    pool: null,
    market: null,
    liquidityHelper: null,
    multicall: null,
  });

  const [chainInfo, setChainInfo] = useState<IChainInfo>({
    yesBid: '0',
    noBid: '0',
    accountYesBalance: '0',
    accountNoBalance: '0',
    accountDaiBalance: '0',
    accountBptBalance: '0',
    poolYesBalance: '0',
    poolYesWeight: '0',
    poolNoBalance: '0',
    poolNoWeight: '0',
    poolDaiBalance: '0',
    poolDaiWeight: '0',
    poolSwapFee: '0',
    poolBptSupply: '0',
  });

  const updateChainInfo = async (contracts: IContracts) => {
    if (
      !web3 ||
      !account ||
      !contracts.multicall ||
      !contracts.pool ||
      !contracts.yesToken ||
      !contracts.noToken ||
      !contracts.daiToken ||
      !marketInfo
    ) {
      return;
    }

    const result = await contracts.multicall.methods
      .aggregate([
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getSpotPrice(dai, marketInfo.yes)
            .encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getSpotPrice(dai, marketInfo.no)
            .encodeABI(),
        },
        {
          target: marketInfo.yes,
          callData: contracts.yesToken.methods.balanceOf(account).encodeABI(),
        },
        {
          target: marketInfo.no,
          callData: contracts.noToken.methods.balanceOf(account).encodeABI(),
        },
        {
          target: dai,
          callData: contracts.daiToken.methods.balanceOf(account).encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods.balanceOf(account).encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getBalance(marketInfo.yes)
            .encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getDenormalizedWeight(marketInfo.yes)
            .encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getBalance(marketInfo.no)
            .encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getDenormalizedWeight(marketInfo.no)
            .encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods.getBalance(dai).encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods
            .getDenormalizedWeight(dai)
            .encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods.getSwapFee().encodeABI(),
        },
        {
          target: marketInfo.pool,
          callData: contracts.pool.methods.totalSupply().encodeABI(),
        },
      ])
      .call();

    const infos = Object.keys(chainInfo).reduce(
      (pendingInfo, infoKey, index) => {
        return {
          ...pendingInfo,
          [infoKey]: web3.eth.abi.decodeParameter(
            'uint256',
            result.returnData[index]
          ),
        };
      },
      {}
    );

    setChainInfo(infos as IChainInfo);
  };

  const swapToken = async (
    fromAmount: string,
    toAmount: string,
    fromTokenAddress: string,
    toTokenAddress: string,
    fromContract: Contract,
    poolContract: Contract
  ) => {
    if (!account || fromAmount === '0' || toAmount === '0') {
      return;
    }

    try {
      const allowance = await fromContract.methods
        .allowance(account, marketInfo.pool)
        .call();

      if (BigNumber.from(allowance).lt(BigNumber.from(fromAmount))) {
        await fromContract.methods
          .approve(marketInfo.pool, MAX_UINT256)
          .send({ from: account });
      }

      const minAmountOut = BigNumber.from(toAmount)
        .mul(BigNumber.from(((100 - slippage) * 1000).toString()))
        .div(BigNumber.from('100000'))
        .toString();

      await poolContract.methods
        .swapExactAmountIn(
          fromTokenAddress,
          fromAmount,
          toTokenAddress,
          minAmountOut,
          MAX_UINT256
        )
        .send({ from: account });

      updateChainInfo(contracts);
    } catch (error) {
      console.error(error);
    }
  };

  const addLiquidity = async (amount: string) => {
    if (
      !contracts.pool ||
      !contracts.daiToken ||
      !contracts.liquidityHelper ||
      !contracts.shareToken ||
      !contracts.market ||
      amount === '0'
    ) {
      return;
    }

    try {
      const allowance = await contracts.daiToken.methods
        .allowance(account, contracts.liquidityHelper.options.address)
        .call();

      if (BigNumber.from(allowance).lt(BigNumber.from(amount))) {
        await contracts.daiToken.methods
          .approve(contracts.liquidityHelper.options.address, MAX_UINT256)
          .send({ from: account });
      }

      const numTicks = BigNumber.from(
        await contracts.market.methods.getNumTicks().call()
      );
      const amountDivNumTicks = BigNumber.from(amount).div(numTicks);
      const mintAmount = amountDivNumTicks.div(BigNumber.from(2));
      const amountOfDAIToAdd = BigNumber.from(amount).div(BigNumber.from(2));

      const poolTotalSupply = BigNumber.from(
        await contracts.pool.methods.totalSupply().call()
      );
      const poolBalanceOfDAI = BigNumber.from(
        await contracts.pool.methods.getBalance(dai).call()
      );

      const poolBalanceOfYes = BigNumber.from(
        await contracts.pool.methods.getBalance(marketInfo.yes).call()
      );
      const poolBalanceOfNo = BigNumber.from(
        await contracts.pool.methods.getBalance(marketInfo.no).call()
      );

      const poolAmountOutDAI = calcPoolTokensFromAmount(
        amountOfDAIToAdd,
        poolBalanceOfDAI,
        poolTotalSupply
      );
      const poolAmountOutYes = calcPoolTokensFromAmount(
        mintAmount,
        poolBalanceOfYes,
        poolTotalSupply
      );
      const poolAmountOutNo = calcPoolTokensFromAmount(
        mintAmount,
        poolBalanceOfNo,
        poolTotalSupply
      );

      const poolAmountOut = bMin(
        bMin(poolAmountOutDAI, poolAmountOutYes),
        poolAmountOutNo
      );

      const allBinaryTokenIds = await contracts.shareToken.methods
        .getTokenIds(market, BINARY_MARKET_OUTCOMES)
        .call();
      const invalidTokenId = allBinaryTokenIds[0];

      let getBackTokenIds = Array(0);
      let tokenIds = Array(0);
      let wrappingAmounts = Array(0);
      let maxAmountsIn = Array(0);

      getBackTokenIds = [invalidTokenId];
      tokenIds = [allBinaryTokenIds[1], allBinaryTokenIds[2]];
      wrappingAmounts = [mintAmount, mintAmount];
      maxAmountsIn = [MAX_UINT256, MAX_UINT256, MAX_UINT256];

      const currentTokens = await contracts.pool.methods
        .getCurrentTokens()
        .call();
      if (currentTokens.length === 4) {
        getBackTokenIds = Array(0);
        tokenIds.push(invalidTokenId);
        wrappingAmounts.push(mintAmount);
        maxAmountsIn.push(MAX_UINT256);
      }

      await contracts.liquidityHelper.methods
        .mintWrapJoin(
          tokenIds,
          wrappingAmounts,
          getBackTokenIds,
          market,
          numTicks,
          mintAmount,
          amountOfDAIToAdd,
          marketInfo.pool,
          poolAmountOut,
          maxAmountsIn
        )
        .send({ from: account });
    } catch (error) {
      console.error(error);
    }
  };

  const removeLiquidity = async (amount: string) => {
    if (
      !web3 ||
      !contracts.pool ||
      !contracts.daiToken ||
      !contracts.liquidityHelper ||
      !contracts.shareToken ||
      !contracts.market
    ) {
      return;
    }

    try {
      const allowance = await contracts.pool.methods
        .allowance(account, contracts.liquidityHelper.options.address)
        .call();

      if (BigNumber.from(allowance).lt(BigNumber.from(amount))) {
        await contracts.pool.methods
          .approve(contracts.liquidityHelper.options.address, MAX_UINT256)
          .send({ from: account });
      }

      const isApprovedForAll = await contracts.shareToken.methods
        .isApprovedForAll(account, contracts.liquidityHelper.options.address)
        .call();
      if (!isApprovedForAll) {
        await contracts.shareToken.methods
          .setApprovalForAll(
            contracts.liquidityHelper.options.address,
            MAX_UINT256
          )
          .send({ from: account });
      }

      const allBinaryTokenIds = await contracts.shareToken.methods
        .getTokenIds(market, BINARY_MARKET_OUTCOMES)
        .call();
      const invalidTokenId = allBinaryTokenIds[0];

      let tokenIds = Array(0);
      let minAmountsOut = Array(0);

      tokenIds = [allBinaryTokenIds[1], allBinaryTokenIds[2]];
      minAmountsOut = ['0', '0', '0'];

      const currentTokens = await contracts.pool.methods
        .getCurrentTokens()
        .call();
      if (currentTokens.length === 4) {
        tokenIds.push(invalidTokenId);
        minAmountsOut.push('0');
      }
      const fingerprint = web3.utils.fromAscii('');

      await contracts.liquidityHelper.methods
        .exitUnWrapRedeem(
          contracts.pool.options.address,
          amount,
          minAmountsOut,
          tokenIds,
          market,
          BINARY_MARKET_OUTCOMES,
          fingerprint
        )
        .send({ from: account });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!web3 || !marketInfo) {
      return;
    }

    const newContracts: IContracts = {
      yesToken: new web3.eth.Contract(abis.erc20.abi, marketInfo.yes),
      noToken: new web3.eth.Contract(abis.erc20.abi, marketInfo.no),
      daiToken: new web3.eth.Contract(abis.erc20.abi, dai),
      shareToken: new web3.eth.Contract(abis.shareToken.abi, shareToken),
      pool: new web3.eth.Contract(abis.bPool.abi, marketInfo.pool),
      market: new web3.eth.Contract(abis.market.abi, market),
      liquidityHelper: new web3.eth.Contract(
        abis.liquidityHelper.abi,
        liquidity_helper
      ),
      multicall: new web3.eth.Contract(abis.multiCall.abi, multicall),
    };
    setContracts(newContracts);

    updateChainInfo(newContracts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, account, marketInfo]);

  return {
    contracts,
    chainInfo,
    updateChainInfo,
    swapToken,
    removeLiquidity,
    addLiquidity,
  };
};
