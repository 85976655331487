import { useEffect, useState } from 'react';
import { useConnectedWeb3Context } from 'contexts';
import { getNetwork } from 'config/network';
import BigNumber from 'bignumber.js';
import abis from 'config/abis';
import { convertAmountToDisplay, convertSpotPriceToDisplay } from 'utils';
import {
  IMarketPosition,
  IPortfolioInfo,
  ILiquidityPosition,
  IBalances,
  IMarket,
} from 'types';

const keysForIBalance: (keyof IBalances)[] = [
  'yesPrice',
  'noPrice',
  'yesBalance',
  'noBalance',
  'poolBalance',
  'poolSupply',
];

export const usePortfolioInfo = () => {
  const { account, networkId, web3 } = useConnectedWeb3Context();
  const {
    info: { dai, marketInfos, multicall },
    balancerGraphQL,
  } = getNetwork(networkId);

  const [portfolio, setPortfolio] = useState<IPortfolioInfo>({
    portfolioValue: '0',
    openPositions: '0',
    cash: '0',
    markets: [],
  });

  const updatePortfolio = async () => {
    if (!web3) {
      return;
    }

    const daiToken = new web3.eth.Contract(abis.erc20.abi, dai);
    const multicallContract = new web3.eth.Contract(
      abis.multiCall.abi,
      multicall
    );

    const daiBalance = await daiToken.methods.balanceOf(account).call();

    const markets: IMarket[] = await Promise.all(
      Object.keys(marketInfos).map(async (market) => {
        const marketInfo = marketInfos[market];
        const contracts = {
          yesToken: new web3.eth.Contract(abis.erc20.abi, marketInfo.yes),
          noToken: new web3.eth.Contract(abis.erc20.abi, marketInfo.no),
          daiToken,
          pool: new web3.eth.Contract(abis.bPool.abi, marketInfo.pool),
          multicall: multicallContract,
        };

        const result = await contracts.multicall.methods
          .aggregate([
            {
              target: marketInfo.pool,
              callData: contracts.pool.methods
                .getSpotPrice(dai, marketInfo.yes)
                .encodeABI(),
            },
            {
              target: marketInfo.pool,
              callData: contracts.pool.methods
                .getSpotPrice(dai, marketInfo.no)
                .encodeABI(),
            },
            {
              target: marketInfo.yes,
              callData: contracts.yesToken.methods
                .balanceOf(account)
                .encodeABI(),
            },
            {
              target: marketInfo.no,
              callData: contracts.noToken.methods
                .balanceOf(account)
                .encodeABI(),
            },
            {
              target: marketInfo.pool,
              callData: contracts.pool.methods.balanceOf(account).encodeABI(),
            },
            {
              target: marketInfo.pool,
              callData: contracts.pool.methods.totalSupply().encodeABI(),
            },
          ])
          .call();

        const balances = keysForIBalance.reduce(
          (pendingInfo, infoKey, index) => {
            return {
              ...pendingInfo,
              [infoKey]: web3.eth.abi.decodeParameter(
                'uint256',
                result.returnData[index]
              ),
            };
          },
          {} as IBalances
        );

        const response = await fetch(balancerGraphQL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `
                {
                  pools(where: {id: "${marketInfo.pool}", publicSwap: true}) {
                    id
                    liquidity
                    totalSwapVolume
                  }
                }
              `,
          }),
        });
        const res = await response.json();
        const liquidity =
          res.data && res.data.pools && res.data.pools[0]
            ? Number(res.data.pools[0].liquidity)
            : 0;
        const sharesYes = convertAmountToDisplay(
          balances.yesBalance,
          marketInfo.yes,
          networkId
        );
        const sharesNo = convertAmountToDisplay(
          balances.noBalance,
          marketInfo.no,
          networkId
        );
        const shareValues = new BigNumber(balances.poolSupply).isZero()
          ? '0'
          : new BigNumber(balances.poolBalance)
              .multipliedBy(new BigNumber(liquidity))
              .dividedBy(new BigNumber(balances.poolSupply))
              .toString();
        const sharesPool = convertAmountToDisplay(
          balances.poolBalance,
          dai,
          networkId
        );
        const yesPrice = convertSpotPriceToDisplay(
          balances.yesPrice,
          marketInfo.yes,
          networkId
        );
        const noPrice = convertSpotPriceToDisplay(
          balances.noPrice,
          marketInfo.no,
          networkId
        );

        const marketPositions: IMarketPosition[] = [];
        if (balances.yesBalance !== '0') {
          marketPositions.push({
            outcome: 'YES',
            priceAvg: yesPrice,
            priceCur: yesPrice,
            plValue: '0',
            plPercent: '0',
            qty: sharesYes,
            valueInit: '0',
            valueCur: (Number(sharesYes) * Number(yesPrice)).toFixed(2),
          });
        }
        if (balances.noBalance !== '0') {
          marketPositions.push({
            outcome: 'No',
            priceAvg: noPrice,
            priceCur: noPrice,
            plValue: '0',
            plPercent: '0',
            qty: sharesNo,
            valueInit: '0',
            valueCur: (Number(sharesNo) * Number(noPrice)).toFixed(2),
          });
        }
        const liquidityPosition: ILiquidityPosition = {
          shares: sharesPool,
          shareValues: shareValues,
          earnedFees: '0',
        };

        return {
          marketAddress: market,
          marketTitle: marketInfo.marketQuestion,
          marketPositions,
          liquidityPosition,
        } as IMarket;
      })
    );

    const cash = Number(convertAmountToDisplay(daiBalance, dai, networkId));
    let openPositions = 0;
    markets.map((market) => {
      market.marketPositions.map(
        (position) => (openPositions += Number(position.valueCur))
      );
    });
    setPortfolio({
      portfolioValue: (cash + openPositions).toFixed(2),
      openPositions: openPositions.toFixed(2),
      cash: cash.toFixed(2),
      markets,
    });
  };

  useEffect(() => {
    if (!web3 || !marketInfos || !account) {
      return;
    }

    updatePortfolio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3, account, marketInfos]);

  return {
    portfolio,
  };
};
