import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import ThemeProvider, { ThemedGlobalStyle } from 'theme';
import Header from 'components/Header';
import Market from 'pages/Market';
import Trade from 'pages/Trade';
import Portfolio from 'pages/Portfolio';
import { ConnectedWeb3 } from 'contexts';
import './font.css';

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider>
        <ThemedGlobalStyle />
        <ConnectedWeb3>
          <HashRouter>
            <Header />
            <Route path="/" exact component={Market} />
            <Route
              path="/trade/:market"
              render={(props) => (
                <Trade market={props.match.params.market as string} />
              )}
            />
            <Route path="/portfolio" exact component={Portfolio} />
          </HashRouter>
        </ConnectedWeb3>
      </ThemeProvider>
    </>
  );
};

export default App;
