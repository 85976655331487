import React from 'react';
import Modal from 'react-modal';
import { withTheme } from 'styled-components';

interface Props extends React.ComponentProps<typeof Modal> {
  children: React.ReactNode;
  theme?: any;
  onRequestClose: () => void;
}

const ModalContainer: React.FC<Props> = (props) => {
  const { children, onRequestClose, theme, ...restProps } = props;
  const { modalStyle } = theme;

  React.useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <Modal
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
      {...restProps}
    >
      {children}
    </Modal>
  );
};

export const ModalWrapper = withTheme(ModalContainer);
