import React, { useCallback } from 'react';
import styled, {
  createGlobalStyle,
  DefaultTheme,
  css,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import { Text, TextProps } from 'rebass';
import { Colors } from './styled';

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToExtraLarge: 1400,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const white = '#FFFFFF';
const black = '#000000';

export const colors: Colors = {
  white,
  black,

  text1: '#14142A',
  text2: '#4E4B66',
  text3: '#6E7191',
  text4: '#B0B0C3',

  bg1: '#F7F7FC',
  bg2: '#F7F7FB',

  border: '#C9C9DC',

  primary: '#14142A',
};

export const theme: DefaultTheme = {
  ...colors,

  grids: {
    sm: 8,
    md: 12,
    lg: 24,
  },

  //shadows
  shadow1: '#2F80ED',

  // media queries
  mediaWidth: mediaWidthTemplates,

  // css snippets
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,

  modalStyle: {
    content: {
      backgroundColor: '##FFFFFF',
      borderRadius: '24px',
      border: 'none',
      boxShadow: '2px 2px 12px rgba(78, 75, 102, 0.08)',
      display: 'flex',
      flexDirection: 'column',
      height: 'fit-content',
      left: 'auto',
      right: 'auto',
      top: 'auto',
      bottom: 'auto',
      margin: 'auto 0',
      overflow: 'hidden',
      padding: '25px',
      position: 'relative',
      width: '60%',
    },
    overlay: {
      alignItems: 'unset',
      backgroundColor: 'rgba(20, 20, 42, 0.4)',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'auto',
      padding: '10px',
      zIndex: 12345,
    },
  },
};

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const StyledContainer = styled.div`
  padding: 0 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0 1.25rem;`};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding: 0 0.5rem !important`}
`;

export const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`;

const UpperTextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
  text-transform: uppercase;
`;

export const TYPE = {
  Header1(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize="44px"
        lineHeight="61px"
        {...props}
      />
    );
  },
  Header2(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize="36px"
        lineHeight="50px"
        {...props}
      />
    );
  },
  Header3(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize="24px"
        lineHeight="33px"
        {...props}
      />
    );
  },
  Header4(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize="20px"
        lineHeight="27px"
        {...props}
      />
    );
  },
  Header5(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize="28px"
        lineHeight="39px"
        {...props}
      />
    );
  },
  Body1(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={300}
        fontSize="18px"
        lineHeight="29px"
        {...props}
      />
    );
  },
  Body2(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize="16px"
        lineHeight="26px"
        {...props}
      />
    );
  },
  Upper1(props: TextProps) {
    return (
      <UpperTextWrapper
        fontWeight={600}
        fontSize="14px"
        lineHeight="19px"
        {...props}
      />
    );
  },
  Upper2(props: TextProps) {
    return (
      <UpperTextWrapper
        fontWeight={500}
        fontSize="12px"
        lineHeight="16px"
        {...props}
      />
    );
  },
  Caption(props: TextProps) {
    return (
      <UpperTextWrapper
        fontWeight={500}
        fontSize="10px"
        lineHeight="14px"
        {...props}
      />
    );
  },
};

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.black};
  font-weight: 500;
  word-break: break-all;
  :hover {
    text-decoration: none;
  }
  :focus {
    outline: none;
    text-decoration: none;
  }
  :active {
    text-decoration: none;
  }
`;

export const ExternalLink = ({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  ...rest
}: Omit<React.HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & {
  href: string;
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      // don't prevent default, don't redirect if it's a new tab
      if (target === '_blank' || event.ctrlKey || event.metaKey) {
      } else {
        event.preventDefault();
      }
    },
    [target]
  );
  return (
    <StyledLink
      target={target}
      rel={rel}
      href={href}
      onClick={handleClick}
      {...rest}
    />
  );
};

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Axiforma' !important;
  background: ${({ theme }) => theme.bg1}
}

button {
  font-family: 'Axiforma' !important;
  outline: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

* {
  box-sizing: border-box;
}

`;
