import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { TYPE } from 'theme';

import { getNetwork } from 'config/network';
import { useConnectedWeb3Context } from 'contexts';

import Badge from 'components/common/Badge';
import MarketInfo from 'components/MarketInfo';
import OrderForm from 'components/Order';

import { useMarket } from 'hooks/useMarket';

const TradeContainer = styled.div`
  padding: 5rem 6.25rem;
`;

const InfoContainer = styled(Box)`
  background: ${({ theme }) => theme.white} !important;
  border-radius: 8px !important;
`;

interface IProps {
  market: string;
}

const Trade: React.FC<IProps> = ({ market }) => {
  const { networkId } = useConnectedWeb3Context();
  const {
    contracts,
    chainInfo,
    swapToken,
    addLiquidity,
    removeLiquidity,
  } = useMarket(market);

  const {
    info: { marketInfos },
  } = getNetwork(networkId);
  const marketInfo = marketInfos[market];

  return (
    <TradeContainer>
      {!!marketInfo ? (
        <>
          <Flex flexDirection="row" mb="1.2rem">
            <InfoContainer width="140px" mr="2rem" />
            <Box width="50%" minWidth="42rem">
              <TYPE.Header5 color="text1" mb="0.625rem">
                {marketInfo.marketQuestion}
              </TYPE.Header5>
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="flex-start"
              >
                {marketInfo.extraInfo.categories.map((category) => (
                  <Badge text={category} key={category} />
                ))}
              </Flex>
            </Box>
          </Flex>

          <Flex flexDirection="row" justifyContent="space-between" mb="1.2rem">
            <Box width="68%">
              <MarketInfo
                market={market}
                marketInfo={marketInfo}
                chainInfo={chainInfo}
                addLiquidity={addLiquidity}
                removeLiquidity={removeLiquidity}
              />
            </Box>
            <Box width="30%" pt="2rem">
              <OrderForm
                market={market}
                contracts={contracts}
                chainInfo={chainInfo}
                swapToken={swapToken}
              />
            </Box>
          </Flex>
        </>
      ) : null}
    </TradeContainer>
  );
};

export default Trade;
