import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { TYPE } from 'theme';

import { getNetwork } from 'config/network';
import { convertAmountToDisplay, convertSpotPriceToDisplay } from 'utils';
import { useConnectedWeb3Context } from 'contexts';
import { IMarketInfo } from 'types';
import BigNumber from 'bignumber.js';

import { ReactComponent as YesIcon } from 'assets/images/yes.svg';
import { ReactComponent as NoIcon } from 'assets/images/no.svg';

import { IChainInfo } from 'types';
import LiquidityModal from './modal/Liquidity';

const InfoContainer = styled(Box)`
  background: ${({ theme }) => theme.white} !important;
  border-radius: 8px !important;
`;

const StyledFilledButton = styled.button`
  border: none;
  width: 12.8rem;
  color: white;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.primary};
  margin-left: 1.2rem;
  border-radius: 6.25rem;
  padding: 1rem 0;
`;

const StyledOutLineButton = styled.button`
  border: none;
  width: 12.8rem;
  color: ${({ theme }) => theme.text1};
  border-radius: 1.5rem;
  background-color: transparent;
  border-radius: 6.25rem;
  padding: 1rem 0;
  border: ${({ theme }) => `1px solid ${theme.border}`}; ;
`;

const ResolutionSource = styled.div`
  border: ${({ theme }) => `1px solid ${theme.border}`};
  background: white;
  padding: 1.25rem;
  border-radius: 0.75rem;
`;

interface IProps {
  market: string;
  marketInfo: IMarketInfo;
  chainInfo: IChainInfo;
  addLiquidity: (amount: string) => void;
  removeLiquidity: (amount: string) => void;
}

const MarketInfo: React.FC<IProps> = ({
  market,
  marketInfo,
  chainInfo,
  addLiquidity,
  removeLiquidity,
}) => {
  const { networkId } = useConnectedWeb3Context();
  const {
    info: { dai },
    balancerGraphQL,
  } = getNetwork(networkId);

  const [liquidity, setLiquidity] = useState('0.00');
  const [volume, setVolume] = useState('0.00');
  const [showLiquidity, setShowLiquidity] = useState<'add' | 'remove' | null>(
    null
  );

  const onCloseLiquidity = () => {
    setShowLiquidity(null);
  };

  const onClickRemove = () => {
    setShowLiquidity('remove');
  };

  const onClickAdd = () => {
    setShowLiquidity('add');
  };

  useEffect(() => {
    fetch(balancerGraphQL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
            {
              pools(where: {id: "${marketInfo.pool}", publicSwap: true}) {
                id
                liquidity
                totalSwapVolume
              }
            }
          `,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data && res.data.pools && res.data.pools[0]) {
          setVolume(Number(res.data.pools[0].totalSwapVolume).toFixed(2));
          setLiquidity(Number(res.data.pools[0].liquidity).toFixed(2));
        }
      });
  }, [marketInfo.pool]);

  const sharesYes = convertAmountToDisplay(
    chainInfo.accountYesBalance,
    marketInfo.yes,
    networkId
  );
  const sharesNo = convertAmountToDisplay(
    chainInfo.accountNoBalance,
    marketInfo.no,
    networkId
  );
  const lpShares = new BigNumber(chainInfo.poolBptSupply).isZero()
    ? 0
    : new BigNumber(chainInfo.accountBptBalance)
        .dividedBy(new BigNumber(chainInfo.poolBptSupply))
        .toString();
  const sharesPool = convertAmountToDisplay(
    chainInfo.accountBptBalance,
    dai,
    networkId
  );
  const yesBid = convertSpotPriceToDisplay(
    chainInfo.yesBid,
    marketInfo.yes,
    networkId
  );
  const noBid = convertSpotPriceToDisplay(
    chainInfo.noBid,
    marketInfo.no,
    networkId
  );

  return (
    <>
      <LiquidityModal
        isOpen={!!showLiquidity}
        addOrRemove={showLiquidity!}
        addLiquidity={addLiquidity}
        removeLiquidity={removeLiquidity}
        onClose={onCloseLiquidity}
      />
      <TYPE.Upper1 color="text3" mb="0.625rem">
        Market information
      </TYPE.Upper1>
      <InfoContainer padding="0.75rem 1.875rem" mb="2.5rem">
        <Flex justifyContent="space-between">
          <Flex>
            <Box mr="5rem">
              <TYPE.Caption color="text3" mb="0.125rem">
                market ends on
              </TYPE.Caption>
              <TYPE.Body2 color="text2">April 1st, 2021</TYPE.Body2>
            </Box>
            <Box mr="5rem">
              <TYPE.Caption color="text3" mb="0.125rem">
                trade volume
              </TYPE.Caption>
              <TYPE.Body2 color="text2"> ${volume}</TYPE.Body2>
            </Box>
            <Box>
              <TYPE.Caption color="text3" mb="0.125rem">
                liquidity
              </TYPE.Caption>
              <TYPE.Body2 color="text2">${liquidity}</TYPE.Body2>
            </Box>
          </Flex>
          <Flex>
            <Box mr="5rem">
              <Flex alignItems="center">
                <YesIcon />
                <Box ml="0.75rem">
                  <TYPE.Caption color="text3" mb="0.125rem">
                    YES
                  </TYPE.Caption>
                  <TYPE.Body2 color="text2">${yesBid}</TYPE.Body2>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex alignItems="center">
                <NoIcon />
                <Box ml="0.75rem">
                  <TYPE.Caption color="text3" mb="0.125rem">
                    NO
                  </TYPE.Caption>
                  <TYPE.Body2 color="text2">${noBid}</TYPE.Body2>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </InfoContainer>

      <TYPE.Upper1 color="text3" mb="0.625rem">
        User's Holdings
      </TYPE.Upper1>
      <Flex
        justifyContent="space-between"
        flexWrap="wrap"
        mb="5rem"
        alignContent="center"
      >
        <InfoContainer padding="0.75rem 1.875rem" mb="1rem" mr="2rem">
          <Flex>
            <Box mr="5rem">
              <TYPE.Caption color="text3" mb="0.125rem">
                shares: yes
              </TYPE.Caption>
              <TYPE.Body2 color="text2">
                {sharesYes} | ${(Number(sharesYes) * Number(yesBid)).toFixed(2)}
              </TYPE.Body2>
            </Box>
            <Box mr="5rem">
              <TYPE.Caption color="text3" mb="0.125rem">
                shares: no
              </TYPE.Caption>
              <TYPE.Body2 color="text2">
                {sharesNo} | ${(Number(sharesNo) * Number(noBid)).toFixed(2)}
              </TYPE.Body2>
            </Box>
            <Box mr="5rem">
              <TYPE.Caption color="text3" mb="0.125rem">
                LP SHARES
              </TYPE.Caption>
              <TYPE.Body2 color="text2">{lpShares}</TYPE.Body2>
            </Box>
            <Box>
              <TYPE.Caption color="text3" mb="0.125rem">
                pool
              </TYPE.Caption>
              <TYPE.Body2 color="text2">{sharesPool}</TYPE.Body2>
            </Box>
          </Flex>
        </InfoContainer>
        <Box>
          <StyledOutLineButton onClick={onClickRemove}>
            REMOVE LIQUIDITY
          </StyledOutLineButton>
          <StyledFilledButton onClick={onClickAdd}>
            ADD LIQUIDITY
          </StyledFilledButton>
        </Box>
      </Flex>

      <TYPE.Header3 color="text1" mb="1.25rem">
        About this market
      </TYPE.Header3>
      <TYPE.Body1 color="text3" mb="2.5rem">
        {marketInfo.extraInfo.longDescription}
      </TYPE.Body1>

      <ResolutionSource>
        <TYPE.Upper1 mb="0.375rem">Resolution Source:</TYPE.Upper1>
        <TYPE.Body1 color="text2">
          whitehouse.gov and
          https://history.house.gov/Institution/Presidents-Coinciding/Presidents-Coinciding/
          and https://www.loc.gov/rr/print/list/057_chron.html
        </TYPE.Body1>
      </ResolutionSource>
    </>
  );
};

export default MarketInfo;
