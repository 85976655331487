import React, { useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { Box, Flex } from 'rebass';

import { usePortfolioInfo } from 'hooks/usePortfolioInfo';

import PortfolioInfo from 'components/PortfolioInfo';
import Tabs from 'components/common/Tabs';
import PortfolioHistory from 'components/PortfolioHistory';
import MarketPositions from 'components/MarketPositions';
import LiquidityPositions from 'components/LiquidityPositions';

const PortfolioContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 5rem 6.25rem;
`;

const Portfolio: React.FC = () => {
  const { portfolio } = usePortfolioInfo();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <PortfolioContainer>
      <Flex>
        <Box width="67%">
          <TYPE.Header2 color="text1" mb="1.25rem">
            Hello, Micheal 👋
          </TYPE.Header2>

          <PortfolioInfo portfolio={portfolio} />

          <Box mt={80}>
            <Tabs
              tabs={['Your market positions', 'Your liquidity positions']}
              selected={selectedIndex}
              onChange={(value) => setSelectedIndex(value)}
            />
          </Box>

          <Box mt={30}>
            {selectedIndex === 0 ? (
              <MarketPositions portfolio={portfolio} />
            ) : (
              <LiquidityPositions portfolio={portfolio} />
            )}
          </Box>
        </Box>

        <Box width="33%" pl="1rem">
          <TYPE.Header2 color="text1" mb="1.25rem">
            History
          </TYPE.Header2>

          <PortfolioHistory />
        </Box>
      </Flex>
    </PortfolioContainer>
  );
};

export default Portfolio;
