import React, { useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

import { ReactComponent as SwapIcon } from 'assets/images/swap.svg';
import CurrencyInput from './common/CurrencyInput';
import { Currency } from 'types';

import { useConnectedWeb3Context } from 'contexts';
import { getNetwork } from 'config/network';

import { IChainInfo, IContracts } from 'types';
import { Contract } from 'web3-eth-contract';

import {
  calcInGivenOut,
  calcOutGivenIn,
  convertAmountToDisplay,
  convertDisplayToAmount,
} from 'utils';

const OrderFormContainer = styled.div`
  background: white;
  border-radius: 0.75rem;
  padding: 1.875rem 1.25rem;
`;

const WantBox = styled.div`
  background: transparent;
  padding: 0 1.25rem;
`;

const PayBox = styled.div`
  background: ${({ theme }) => theme.bg1} !important;
  padding: 3.75rem 1.25rem;
  position: relative;
  margin-bottom: 2.5rem;
  margin-top: 3.125rem;
  border-radius: 12px;

  > svg {
    position: absolute;
    left: 1.25rem;
    top: -2rem;
    cursor: pointer;
  }
`;

const StyledFilledButton = styled.button`
  border: none;
  color: white;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 6.25rem;
  padding: 1rem 0;
  width: 100%;
`;

interface IFormInput {
  currency: Currency;
  value: number;
}

interface IProps {
  market: string;
  contracts: IContracts;
  chainInfo: IChainInfo;
  swapToken: (
    fromAmount: string,
    toAmount: string,
    fromTokenAddress: string,
    toTokenAddress: string,
    fromContract: Contract,
    poolContract: Contract
  ) => void;
}

const OrderForm: React.FC<IProps> = ({
  market,
  contracts,
  chainInfo,
  swapToken,
}) => {
  const { account, networkId } = useConnectedWeb3Context();
  const {
    info: { dai: daiAddress, marketInfos },
  } = getNetwork(networkId);
  const marketInfo = marketInfos[market];

  const [formInput, setFormInput] = useState<IFormInput[]>([
    { currency: 'YES', value: 0 },
    { currency: 'USDC', value: 0 },
  ]);

  const syncValues = (formInput: IFormInput[], base: number) => {
    const opposite = (base + 1) % 2;
    const yesInteract =
      formInput[base].currency === 'YES' ||
      formInput[opposite].currency === 'YES';
    const isBuy = formInput[1].currency === 'USDC';
    const activePredictPoolBalance = yesInteract
      ? chainInfo.poolYesBalance
      : chainInfo.poolNoBalance;
    const activePredictPoolWeight = yesInteract
      ? chainInfo.poolYesWeight
      : chainInfo.poolNoWeight;
    const activePredictToken = yesInteract ? marketInfo.yes : marketInfo.no;

    let output = '';
    if (base === 0) {
      output = calcInGivenOut(
        isBuy ? chainInfo.poolDaiBalance : activePredictPoolBalance,
        isBuy ? chainInfo.poolDaiWeight : activePredictPoolWeight,
        isBuy ? activePredictPoolBalance : chainInfo.poolDaiBalance,
        isBuy ? activePredictPoolWeight : chainInfo.poolDaiWeight,
        convertDisplayToAmount(
          formInput[base].value.toString() || '0',
          isBuy ? activePredictToken : daiAddress,
          networkId
        ),
        chainInfo.poolSwapFee
      );
    } else {
      output = calcOutGivenIn(
        isBuy ? chainInfo.poolDaiBalance : activePredictPoolBalance,
        isBuy ? chainInfo.poolDaiWeight : activePredictPoolWeight,
        isBuy ? activePredictPoolBalance : chainInfo.poolDaiBalance,
        isBuy ? activePredictPoolWeight : chainInfo.poolDaiWeight,
        convertDisplayToAmount(
          formInput[base].value.toString() || '0',
          isBuy ? daiAddress : activePredictToken,
          networkId
        ),
        chainInfo.poolSwapFee
      );
    }
    const formatOutput = convertAmountToDisplay(
      output,
      formInput[opposite].currency === 'USDC' ? daiAddress : activePredictToken,
      networkId
    );
    formInput[opposite].value = isNaN(Number(formatOutput))
      ? 0
      : Number(formatOutput);
    setFormInput([...formInput]);
  };

  const onClickSwap = () => {
    setFormInput([formInput[1], formInput[0]]);
  };

  const onChangeCurrency = (changedCurrency: Currency, index: number) => {
    const prevFormInput = formInput;
    const opposite = (index + 1) % 2;

    if (
      (formInput[opposite].currency === 'USDC' && changedCurrency !== 'USDC') ||
      (formInput[opposite].currency !== 'USDC' && changedCurrency === 'USDC')
    ) {
      prevFormInput[index].currency = changedCurrency;
    } else {
      prevFormInput[opposite].currency = prevFormInput[index].currency;
      prevFormInput[index].currency = changedCurrency;
    }

    syncValues(prevFormInput, index);
  };

  const onChangeValue = (value: number, index: number) => {
    const prevFormInput = formInput;
    prevFormInput[index].value = value;
    syncValues(prevFormInput, index);
  };

  const onClickSendOrder = () => {
    if (
      !account ||
      !contracts.daiToken ||
      !contracts.yesToken ||
      !contracts.noToken ||
      !contracts.pool
    ) {
      return;
    }

    const yesInteract =
      formInput[0].currency === 'YES' || formInput[1].currency === 'YES';
    const isBuy = formInput[1].currency === 'USDC';
    const activePredictToken = yesInteract ? marketInfo.yes : marketInfo.no;

    swapToken(
      convertDisplayToAmount(
        formInput[1].value.toString(),
        isBuy ? daiAddress : activePredictToken,
        networkId
      ),
      convertDisplayToAmount(
        formInput[0].value.toString(),
        isBuy ? activePredictToken : daiAddress,
        networkId
      ),
      isBuy ? daiAddress : activePredictToken,
      isBuy ? activePredictToken : daiAddress,
      isBuy
        ? contracts.daiToken
        : yesInteract
        ? contracts.yesToken
        : contracts.noToken,
      contracts.pool
    );
  };

  return (
    <OrderFormContainer>
      <WantBox>
        <TYPE.Header4 color="text2" mb="1.25rem">
          I Want
        </TYPE.Header4>
        <CurrencyInput
          index={0}
          currency={formInput[0].currency}
          value={formInput[0].value}
          bgColor="bg1"
          changeCurrency={onChangeCurrency}
          changeValue={onChangeValue}
        />
      </WantBox>
      <PayBox>
        <SwapIcon onClick={onClickSwap} />
        <TYPE.Header4 color="text2" mb="1.25rem">
          I Pay
        </TYPE.Header4>
        <CurrencyInput
          index={1}
          currency={formInput[1].currency}
          value={formInput[1].value}
          bgColor="white"
          changeCurrency={onChangeCurrency}
          changeValue={onChangeValue}
        />
      </PayBox>
      <StyledFilledButton onClick={onClickSendOrder}>
        SEND ORDER
      </StyledFilledButton>
      <TYPE.Upper2
        color="border"
        mt="1.25rem"
        sx={{ textTransform: 'lowercase' }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.{' '}
      </TYPE.Upper2>
    </OrderFormContainer>
  );
};

export default OrderForm;
