import { IABIS } from 'types';

import bActions from './abis/bActions.json';
import bFactory from './abis/bFactory.json';
import bPool from './abis/bPool.json';
import dsProxy from './abis/dsProxy.json';
import dsProxyRegistry from './abis/dsProxyRegistry.json';
import erc20 from './abis/erc20.json';
import erc20Wrapper from './abis/erc20Wrapper.json';
import exchangeProxy from './abis/exchangeProxy.json';
import liquidityHelper from './abis/liquidityHelper.json';
import market from './abis/market.json';
import multiCall from './abis/multiCall.json';
import shareToken from './abis/shareToken.json';
import weth from './abis/weth.json';
import { ABIKeys } from './network';

const abis: IABIS = {
  [ABIKeys.bActions]: bActions,
  [ABIKeys.bFactory]: bFactory,
  [ABIKeys.bPool]: bPool,
  [ABIKeys.dsProxy]: dsProxy,
  [ABIKeys.dsProxyRegistry]: dsProxyRegistry,
  [ABIKeys.erc20]: erc20,
  [ABIKeys.exchangeProxy]: exchangeProxy,
  [ABIKeys.weth]: weth,
  [ABIKeys.multiCall]: multiCall,
  [ABIKeys.liquidityHelper]: liquidityHelper,
  [ABIKeys.shareToken]: shareToken,
  [ABIKeys.market]: market,
  [ABIKeys.erc20Wrapper]: erc20Wrapper,
};

export default abis;
