import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { theme, TYPE } from 'theme';
import { IPortfolioInfo } from 'types';

import { ReactComponent as ImgIcon } from 'assets/images/icn_image.svg';

const ItemContainer = styled(Box)`
  background-color: ${({ theme }) => theme.white} !important;
  padding: 1rem;
  box-shadow: 2px 2px 12px rgba(78, 75, 102, 0.08);
  border-radius: 12px;
  &:last-child {
    margin-bottom: 0 !important;
  }
`;

const ImageWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.bg2};
  width: 140px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFilledButton = styled.button`
  background-color: ${({ theme }) => theme.text1};
  border: none;
  color: white;
  border-radius: 6.25rem;
  width: 110px;
  height: 42px;
`;

const InfoWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.bg2};
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
`;

interface IProps {
  portfolio: IPortfolioInfo;
}

const MarketPositions: React.FC<IProps> = ({ portfolio }) => {
  const history = useHistory();

  const nonEmptyMarkets = portfolio.markets.filter(
    (market) => market.marketPositions.length > 0
  );

  const onClickTrade = (market: string) => {
    history.push(`/trade/${market}`);
  };

  return (
    <Box>
      {nonEmptyMarkets.map((market, index) => (
        <ItemContainer mb={20} key={index}>
          <Flex alignItems="center">
            <ImageWrapper mr={20}>
              <ImgIcon />
            </ImageWrapper>

            <Box width="calc(100% - 160px)">
              <Flex justifyContent="space-between" mb={10}>
                <TYPE.Header4 width="70%">{market.marketTitle}</TYPE.Header4>
                <StyledFilledButton
                  onClick={() => onClickTrade(market.marketAddress)}
                >
                  TRADE
                </StyledFilledButton>
              </Flex>

              <InfoWrapper>
                <Flex>
                  <Box mr="3.125rem">
                    <TYPE.Caption color={theme.text3}>Outcome</TYPE.Caption>
                    {market.marketPositions.map((position) => (
                      <>
                        <TYPE.Upper1
                          lineHeight="22px"
                          letterSpacing="0.03rem"
                          mt="2px"
                        >
                          {position.outcome}
                        </TYPE.Upper1>
                      </>
                    ))}
                  </Box>
                  <Box mr="3.125rem">
                    <TYPE.Caption color={theme.text3}>
                      price avg | Cur.
                    </TYPE.Caption>
                    {market.marketPositions.map((position) => (
                      <>
                        <TYPE.Upper1
                          lineHeight="22px"
                          letterSpacing="0.03rem"
                          mt="2px"
                        >
                          ${position.priceAvg} | ${position.priceCur}
                        </TYPE.Upper1>
                      </>
                    ))}
                  </Box>
                  <Box mr="3.125rem">
                    <TYPE.Caption color={theme.text3}>P/l: $ | %</TYPE.Caption>
                    {market.marketPositions.map((position) => (
                      <>
                        <TYPE.Upper1
                          lineHeight="22px"
                          letterSpacing="0.03rem"
                          mt="2px"
                        >
                          {position.plValue} | {position.plPercent}
                        </TYPE.Upper1>
                      </>
                    ))}
                  </Box>
                  <Box mr="3.125rem">
                    <TYPE.Caption color={theme.text3}>qty</TYPE.Caption>
                    {market.marketPositions.map((position) => (
                      <>
                        <TYPE.Upper1
                          lineHeight="22px"
                          letterSpacing="0.03rem"
                          mt="2px"
                        >
                          {position.qty}
                        </TYPE.Upper1>
                      </>
                    ))}
                  </Box>
                  <Box>
                    <TYPE.Caption color={theme.text3}>
                      {' '}
                      Value: init. | cur.
                    </TYPE.Caption>
                    {market.marketPositions.map((position) => (
                      <>
                        <TYPE.Upper1
                          lineHeight="22px"
                          letterSpacing="0.03rem"
                          mt="2px"
                        >
                          {position.valueCur}
                        </TYPE.Upper1>
                      </>
                    ))}
                  </Box>
                </Flex>
              </InfoWrapper>
            </Box>
          </Flex>
        </ItemContainer>
      ))}
    </Box>
  );
};

export default MarketPositions;
