import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { theme, TYPE } from 'theme';

import { ReactComponent as CartIcon } from 'assets/images/icn_cart.svg';
import { ReactComponent as SellIcon } from 'assets/images/icn_sell.svg';
import { ReactComponent as AddIcon } from 'assets/images/icn_add.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/icn_remove.svg';

const HistoryItemContainer = styled(Box)`
  height: 72px;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.bg1};
  &:last-child {
    border-bottom: none;
    margin-bottom: 0 !important;
    height: 60px;
  }
`;

const HistoryIconWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.bg2};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemDescription = styled(TYPE.Upper2)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

type HistoryItemType = {
  icon?: React.ReactElement;
  type: string;
  description: string;
  value: number;
  time: string;
};

interface HistoryItemProps {
  item: HistoryItemType;
}

const HistoryItem: React.FC<HistoryItemProps> = ({ item }) => {
  return (
    <HistoryItemContainer mb="1rem">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" width="80%">
          <HistoryIconWrapper mr={12}>{item.icon}</HistoryIconWrapper>

          <Box maxWidth="calc(100% - 80px)">
            <TYPE.Upper2 color={theme.text3}>{item.type}</TYPE.Upper2>
            <ItemDescription
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="0.03rem"
              fontWeight={600}
            >
              {item.description}
            </ItemDescription>
          </Box>
        </Flex>

        <Flex flexDirection="column" alignItems="flex-end">
          <Flex alignItems="center">
            <TYPE.Upper1
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="0.03rem"
              fontWeight={600}
            >
              ${Math.floor(item.value)}
            </TYPE.Upper1>

            <TYPE.Upper1
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="0.03rem"
              fontWeight={600}
              color={theme.text4}
            >
              {Math.round((item.value - Math.floor(item.value)) * 100)
                .toFixed(2)
                .slice(1)}
            </TYPE.Upper1>
          </Flex>

          <TYPE.Upper2 color={theme.text3}>{item.time}</TYPE.Upper2>
        </Flex>
      </Flex>
    </HistoryItemContainer>
  );
};

const HistoryContainer = styled(Box)`
  background-color: ${({ theme }) => theme.white} !important;
  border-radius: 8px !important;
  padding: 1.5rem 1rem;
`;

const histories: HistoryItemType[] = [
  {
    icon: <CartIcon />,
    type: 'bought',
    description: `Who will the world's richest person be on Febrary 27, 2021?`,
    value: 10,
    time: '7:24 am',
  },
  {
    icon: <SellIcon />,
    type: 'sell',
    description: `Will Raptors or the Bucks win their Febrary 18th matchup?`,
    value: 3,
    time: '7:20 am',
  },
  {
    icon: <AddIcon />,
    type: 'add liquidity',
    description: `Who will the world's richest person be on Febrary 27, 2021?`,
    value: 10,
    time: '7:24 am',
  },
  {
    icon: <RemoveIcon />,
    type: 'remove liquiduty',
    description: `Will Raptors or the Bucks win their Febrary 18th matchup?`,
    value: 3,
    time: '7:20 am',
  },
];

const PortfolioHistory: React.FC = () => {
  return (
    <HistoryContainer>
      {histories.map((item, idx) => (
        <HistoryItem item={item} key={idx} />
      ))}
    </HistoryContainer>
  );
};

export default PortfolioHistory;
