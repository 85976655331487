import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { TextWrapper } from 'theme';
import { Link } from 'react-router-dom';
import { useConnectedWeb3Context } from 'contexts';
import { shortenAddress } from 'utils';
import { useLocation } from 'react-router-dom';

const HeaderContainer = styled.div`
  background-color: white;
  overflow: overlay;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  padding: 1.5rem 6.25rem;
`;

const ConnectWallet = styled.button`
  outline: none;
  border: none;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  padding: 0.75rem 2rem !important;
  font-size: 0.75rem;
  border-radius: 6.25rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NavItem = styled(StyledLink)<{ isactive?: boolean }>`
  font-size: 1rem;
  padding: 0 2.5rem;
  color: ${({ theme, isactive }) => (isactive ? theme.text1 : theme.text2)};
`;

export const Header = (props: { left?: JSX.Element; right?: JSX.Element }) => {
  const { account, connectWeb3 } = useConnectedWeb3Context();
  const location = useLocation();

  return (
    <HeaderContainer>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledLink to="/">
          <TextWrapper color="text1" fontSize="1.25rem">
            CALCHAS
          </TextWrapper>
        </StyledLink>
        <Flex>
          <NavItem to="/" isactive={location.pathname === '/'}>
            MARKET
          </NavItem>
          <NavItem
            to="/portfolio"
            isactive={location.pathname === '/portfolio'}
          >
            PORTFOLIO
          </NavItem>
          {/* <NavItem to="/trade" isactive={location.pathname === '/trade'}>
            LP
          </NavItem> */}
        </Flex>
        {account ? (
          <TextWrapper color="text1" fontSize="1rem">
            {shortenAddress(account)}
          </TextWrapper>
        ) : (
          <ConnectWallet onClick={() => connectWeb3()}>LOG IN</ConnectWallet>
        )}
      </Flex>
    </HeaderContainer>
  );
};

export default Header;
