import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { theme, TYPE } from 'theme';
import { IPortfolioInfo } from 'types';

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.white} !important;
  border-radius: 12px;
  padding-bottom: 1.25rem;
`;

const ItemContainer = styled(Box)`
  padding: 1.25rem 2.5rem;
  border-top: 2px solid ${({ theme }) => theme.bg1};
  &:first-child {
    border-top: none;
  }
`;
interface IProps {
  portfolio: IPortfolioInfo;
}

const LiquidityPositions: React.FC<IProps> = ({ portfolio }) => {
  const nonEmptyMarkets = portfolio.markets.filter(
    (market) => Number(market.liquidityPosition.shares) > 0
  );

  return (
    <Container>
      <ItemContainer>
        <Flex>
          <TYPE.Upper2 flex={1} color={theme.text4}>
            Liquidity shares
          </TYPE.Upper2>
          <TYPE.Upper2 flex={1} color={theme.text4}>
            Shares Value
          </TYPE.Upper2>
          <TYPE.Upper2 flex={1} color={theme.text4}>
            Earned Fees
          </TYPE.Upper2>
        </Flex>
      </ItemContainer>

      {nonEmptyMarkets.map((item, idx) => (
        <ItemContainer key={idx}>
          <Flex>
            <TYPE.Body2 flex={1}>{item.liquidityPosition.shares}</TYPE.Body2>
            <TYPE.Body2 flex={1}>
              ${item.liquidityPosition.shareValues}
            </TYPE.Body2>
            <TYPE.Body2 flex={1}>
              ${item.liquidityPosition.earnedFees}
            </TYPE.Body2>
          </Flex>
        </ItemContainer>
      ))}
    </Container>
  );
};

export default LiquidityPositions;
